import React, { useState } from "react";
import { render } from "react-dom";
import "./product.scss";

import SignUp from "../../sign-up/sign-up.component.class";
import RetroButton from "../../../components/retro/button/retro-button.component";
import BoxLayer from "../../../components/retro/box-layer/box-layer.component";

import Lottie from "lottie-react";

// import '@lottiefiles/lottie-player';
// import { create } from '@lottiefiles/lottie-interactivity';

import WorkingTogether from "./lottie/WorkingTogether.json";
import CardStack from "../components/card-stack";

import { FadeInWhenVisible, FadeInToLeft, LefttoRightFadeIn } from "./features";

import { motion } from "framer-motion";

export default function SectionProduct() {
  const [showRegister, setShowRegister] = useState(false);

  const interactivity = {
    mode: "scroll",
    actions: [
      {
        visibility: [0, 0.2],
        type: "stop",
        frames: [0],
      },
      {
        visibility: [0.2, 0.45],
        type: "seek",
        frames: [0, 45],
      },
      {
        visibility: [0.45, 1.0],
        type: "loop",
        frames: [45, 60],
      },
    ],
  };

  const Projects = [
    {
      title: "Personal Dashboard",
      color: "green",
      img: "/homepage/home_dashboard.jpg",
    },
    {
      title: "Workspace Dashboard",
      color: "blue",
      img: "/homepage/workspace_dashboard.jpg",
    },
    {
      title: "Project Page",
      color: "orange",
      img: "/homepage/project_page.jpg",
    },
  ];

  const renderStackedHeroCard = (personfeature) => {
    const { title, color, img } = personfeature;
    return (
      <LefttoRightFadeIn>
        <div className="sec_hero-stacked-card-inner">
          <img src={img} width="100%" height="100%" />
          <div className="sec_hero-stacked-card-header">
            <h4>{title}</h4>
          </div>
        </div>
      </LefttoRightFadeIn>
    );
  };

  return (
    <section id="product" className="sec__product">
      <motion.div
        className="product__hero-info"
        initial={{ x: "50%", opacity: 0, scale: 0.5 }}
        animate={{ x: 0, opacity: 1, scale: 1 }}
        transition={{ duration: 1, ease: "easeOut" }}
      >
        <div className="sec__product-hero-header">
          <h3>Your all-in-one</h3>
          <h2>Life / Project Manager</h2>
          <h3>& Collaboration Hub</h3>
        </div>
        <p>
          Antlogy is an online platform designed to deal with the complexity of
          long-term goals and projects with its well-defined minimalist design.
        </p>
        <p>
          Comes with flexible ways to manage your goals and projects, but still
          feels natural like paper, even for newcomers.
        </p>
        <RetroButton
          // style={{ background: "#3b3b3b", color: "white" }}
          onClick={() => setShowRegister(!showRegister)}
        >
          Get Started for Free
        </RetroButton>
      </motion.div>
      <div className="visual__container">
        <div className="sec_hero-stacked">
          {Projects.map((personfeature) => {
            const { title, color, img } = personfeature;
            return (
              <div className="sec_hero-stacked-card" key={title}>
                {renderStackedHeroCard(personfeature)}
              </div>
            );
          })}
        </div>
        {/* <CardStack /> */}
        {/* <img
          src="/homepage/home_dashboard.jpg"
          // height="250px"
          alt=""
        /> */}
        <div className="storebadges__container">
          {/* <h4>Soon available in mobile as well:</h4>
        <img
          src="media/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg"
          height="50px"
          alt="Get it on Google Play Badge"
        />
        <img
          src="media/GetItOnGooglePlay_Badge_Web_color_English.png"
          height="50px"
          alt="
          Get it on Google Play Badge"
        /> */}
        </div>
      </div>
      {showRegister && (
        <BoxLayer setLayer={setShowRegister}>
          <SignUp />
        </BoxLayer>
      )}
    </section>
  );
}

import React from "react";
import { useRef } from "react";

import { useInView } from "framer-motion";

import Lottie from "lottie-react";

import WorkingTogether from "./lottie/WorkingTogether.json";
import ChatFriends from "./lottie/ChatFriends.json";

import PersonalCalVisual from "../../../assets/illustration/undraw_booked_re_vtod.svg";
import PersonalNoteVisual from "../../../assets/illustration/undraw_noted_re_c5wv.svg";
import PersonalTaskVisual from "../../../assets/illustration/undraw_accept_request_re_d81h.svg";
import PersonalHabitVisual from "../../../assets/illustration/undraw_personal_goals_re_iow7.svg";
import PersonalTimerVisual from "../../../assets/illustration/undraw_time_management_re_tk5w.svg";

import { motion } from "framer-motion";

import "./features.scss";
import SectionCollaboration from "./collaboration";

import images from "../images";

const PersonalFeatures = [
  {
    Header: "Calendar",
    Content:
      "Use the time-blocking time management technique for yourself and your team to track all the tasks related to your projects.",
    Visual: PersonalCalVisual,
  },
  {
    Header: "Journal",
    Content:
      "Jot down your ideas quickly before creating tasks and notes, or journal about the day to remind yourself simple stuff.",
    Visual: PersonalNoteVisual,
  },
  {
    Header: "Task Manager",
    Content:
      "Schedule your personal and your teams' tasks with ease. A task can also be assigned to a specific person.",
    Visual: PersonalTaskVisual,
  },
  {
    Header: "Goal & Habit Tracker",
    Content:
      "Adopt to some of our recommended habits or create your own custom ones.",
    Visual: PersonalHabitVisual,
  },
  {
    Header: "Pomodoro Timer",
    Content:
      "Use the popular time management method to stay focused while working. Set custom intervals and with the right session/break proportion for you.",
    Visual: PersonalTimerVisual,
  },
];

export function LefttoRightFadeIn({ children }) {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
    <section ref={ref}>
      <span
        style={{
          transform: isInView ? "none" : "translateX(-500px)",
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
        }}
      >
        {children}
      </span>
    </section>
  );
}

export function FadeInToLeft({ children }) {
  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      transition={{ duration: 0.5 }}
      variants={{
        visible: { opacity: 1, scale: 1 },
        hidden: { opacity: 0, scale: 0 },
      }}
    >
      {children}
    </motion.div>
  );
}

export function FadeInWhenVisible({ children }) {
  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      transition={{ duration: 1 }}
      variants={{
        visible: { opacity: 1, scale: 1 },
        hidden: { opacity: 0, scale: 0.4 },
      }}
    >
      {children}
    </motion.div>
  );
}

export default function SectionFeatures() {
  const interactivity = {
    mode: "scroll",
    actions: [
      // {
      //   visibility: [0, 0.2],
      //   type: "stop",
      //   frames: [0],
      // },
      {
        visibility: [0, 1],
        type: "seek",
        frames: [0, 150],
      },
      // {
      //   visibility: [0.45, 1.0],
      //   type: "loop",
      //   frames: [120, 150],
      // },
    ],
  };

  // const LINE_VARIANTS = {
  //   visible: { height: "75vh", transition: { duration: 2 } },
  //   hidden: { height: "0vh" }
  // };

  return (
    <section id="features" className="sec__features">
      <div>
        <LefttoRightFadeIn>
          <h2>Your Personal Assistant</h2>
          <p>Plan the day(s) ahead to increase productivity.</p>
          {/* <p>
            Being able to visualize your future can reduce stress by providing a
            healthy outlet for emotions and worries.
          </p>
          <p>
            Antlogy provides useful integrated tools to make managing your life
            easier. So you can organize everything quickly.
          </p> */}
        </LefttoRightFadeIn>
      </div>
      <div className="sec_feature-stacked">
        {PersonalFeatures.map((personfeature) => {
          const { Header, Content, Visual } = personfeature;
          return (
            <div className="sec_feature-stacked-card" key={Header}>
              <div className="sec_feature-stacked-card-inner">
                <h4>{Header}</h4>
                <FadeInWhenVisible>
                  <img src={Visual} width="100%" height="100%" />
                </FadeInWhenVisible>
              </div>
              <div className="sec_feature-stacked-card-inner-content">
                <p>{Content}</p>
              </div>
            </div>
          );
        })}
      </div>
      {/* <SectionCollaboration /> */}
      {/* <motion.div className="carousel">
        <motion.div
          drag="x"
          dragConstraints={{ right: 0, left: -600 }}
          className="inner-carousel"
        >
          {PersonalFeatures.map((personfeature) => {
            const { Header, Content, Visual } = personfeature;
            return (
              <div className="sec_feature-card">
                <div>
                  <div>
                    <h4>{Header}</h4>
                  </div>
                  <div>
                    <p>{Content}</p>
                  </div>
                </div>
                <FadeInWhenVisible>
                  <img src={Visual} width="250" height="150" />
                </FadeInWhenVisible>
              </div>
            );
          })}
        </motion.div>
      </motion.div> */}
    </section>
  );
}
